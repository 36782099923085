<template>
    <div id="footer" class="flex-colum flex-align-center">
        <div class="footer-top flex-row">
            <div class="footer-top-nav flex-colum" style="width: 22.88rem; height: 18.25rem">
                <div class="row">
                    <div class="row-title">联系我们</div>
                </div>
                <div class="row flex-row" style="margin-bottom: 1.38rem">
                    <div class="row-left flex-colum flex-align-start">
                        <span>【中国总部】</span>
                    </div>
                    <div class="row-right" style="margin-top: -0.2rem">
                        <div class="row flex-row flex-align-center" style="margin-bottom: 1rem">
                            <i><my-image src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/footer/电 话.png"/></i>
                            <span>联系电话：13761046015</span>
                        </div>
                        <div class="row flex-colum" style="width: 15.25rem">
                            <div class="flex-row"><i style="margin-top: 0;height: 0.75rem"><my-image width="0.75rem" height="0.75rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/footer/地址.png"/></i>
                            <span>公司地址：上海市长宁区金钟路968号凌空</span></div>
                            <span style="margin-left: 1.2rem">SOHO 3号楼201室</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="row flex-row">
                    <div class="row-left flex-colum flex-align-start" style="width: 5.3rem">
                        <span>【英国】</span>
                    </div>
                    <div class="row-right" style="margin-top: -0.1rem">
                        <div class="row flex-colum" style="margin-bottom: 0.88rem;width: 12.58rem">
                            <span>Tel：+44 01618175038</span></div>
                        </div>
                        <div class="row flex-row flex-align-center" style="margin-bottom: 1rem">
                            <i><my-image src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/footer/邮箱.png"/></i>
                            <span>Email：info@minhandgroup.com</span>
                        </div>
                        <div class="row flex-row flex-align-center" style="margin-bottom: 0.38rem">
                            <i><my-image src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/footer/地址.png"/></i>
                            <span>Address：</span>
                        </div>
                        <div class="row flex-colum flex-jusity-center">
                            <span>Orega King Street, 76 King St, Manchester, M2 4NH</span>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="foot-top-nav flex-colum" style="margin-left: 7.56rem">
                <div class="row" style="margin-bottom: 1.25rem">
                    <my-image :drag="false" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/footer/logo.png" style="width:50%;height:60%"/>
                </div>
                <div class="row flex-row">
                    <div class="flex-colum">
                        <my-image :drag="false" width="6.44rem" height="6.44rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/IMG_1146.PNG.JPG"/>
                        <span style="color: #FFFFFF;font-size: 0.75rem">中国客服</span>
                    </div>
                    <!-- <div class="flex-colum" style="width: 6.44rem;margin-left: 5.44rem">
                        <my-image :drag="false" width="6.44rem" height="6.44rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/footer/20191223164307_64022.png"/>
                        <span style="color: #FFFFFF;font-size: 0.75rem">英国客服</span>
                    </div> -->
                </div>
            </div>
            
            <div class="foot-top-nav flex-row" style="width:15.75rem; height: 6.88rem;margin-left: 9.94rem">
                <div class="row-left flex-colum">
                    <nav>产品服务</nav>
                    <span><a :href="`http://www.manbosi.com/mbs/uk.html?pc=`+this.$route.query.pc"><span>英国</span></a></span>
                    <span><a :href="`http://www.manbosi.com/mbs/usa.html?pc=`+this.$route.query.pc"><span>美国</span></a></span>
                    <span><a :href="`http://www.manbosi.com/mbs/aus.html?pc=`+this.$route.query.pc"><span>澳洲</span></a></span>
                    <span><a :href="`http://www.manbosi.com/mbs/hk.html?pc=`+this.$route.query.pc" ><span>香港澳门</span></a></span>
                    <span><a :href="`http://www.manbosi.com/mbs/sg.html?pc=`+this.$route.query.pc"><span>新加坡</span></a></span>
                    <span><a :href="`http://www.manbosi.com/mbs/can.html?pc=`+this.$route.query.pc"><span>加拿大</span></a></span>
                    <span><a :href="`http://www.manbosi.com/mbs/eur.html?pc=`+this.$route.query.pc"><span>欧洲</span></a></span>

                    <!-- <span><a href="https://www.minhandedu.com/xyfd/">学业辅导</a></span> -->
                    <!-- <span><a href="https://www.minhandedu.com/kyjy/">科研教育</a></span> -->
                </div>
                <div class="row-right flex-colum">
                    <nav>加入我们</nav>
                    <!-- <span><a href="http://www.manbosi.com/aboutMe/">关于我们</a></span> -->
                    <span><a :href="`http://www.manbosi.com/tutor/#/?pc=`+this.$route.query.pc">师资力量</a></span>
                    <span><a :href="`http://www.manbosi.com/student/#/?pc=`+this.$route.query.pc">学员案例</a></span>
                    <span><a :href="`http://www.manbosi.com/aboutMe/#/?pc=`+this.$route.query.pc">品牌简介</a></span>
                    <!-- <span><a href="https://www.minhandedu.com/jiaruwomen/">联系我们</a></span> -->
                </div>
            </div>
        </div>
        <div class="line">
        </div>
        <div class="footer-bottom">
            <a href="https://beian.miit.gov.cn/">Copyright &#169 曼汉教育科技（上海）有限公司. All Rights Reserved. 沪ICP备18021532号-4 网站申明</a>
        </div>
    </div>
</template>

<script>
import MyImage from '@components/MyImage';
export default {
    components:{
        MyImage,
    }
}
</script>

<style lang="scss" scoped>
    a{
        color: inherit;
        text-decoration: none;
    }
    #footer{
        height: 100%;
        height: 30.75rem;
        background: #363D45;
        .footer-top{
            margin-top: 2.31rem;
            .foot-top-nav{
                .row-left{
                    nav{
                        color: #FFFFFF; 
                        font-size:1rem;
                        margin-bottom: 0.94rem;
                        cursor: pointer;
                    }
                    span{
                        color: #999999; 
                        font-size:0.75rem;
                        margin-bottom: 0.63rem;
                        word-break: keep-all;
                        cursor: pointer;
                    }
                }
                .row-right{
                    margin-left: 7.75rem;
                        word-break: keep-all;
                    nav{
                        color: #FFFFFF; 
                        font-size:1rem;
                        margin-bottom: 0.94rem;
                        cursor: pointer;
                    }
                    span{
                        color: #999999; 
                        font-size:0.75rem;
                        margin-bottom: 0.63rem;
                        word-break: keep-all;
                        cursor: pointer;
                    }
                }
            }
            .footer-top-nav{
                .row{
                    .row-left{
                        width: 4.5rem;
                        span{
                            font-size: 0.75rem;
                            color: #fff;
                            line-height: 1.06rem;
                            word-break: keep-all;
                        }
                    }
                    .row-right{
                        color: #BCBCBC;
                        .row{
                            i{
                                margin-top: 0.15rem;
                                margin-right: 0.38rem;
                                display: inline-block;
                            }
                            span{
                                // display: block;
                                font-size: 0.75rem;
                                color: #BCBCBC;
                                line-height: 1.06rem;
                                word-break: keep-all;
                            }
                        }
                    }
                    .row-title{
                        font-weight: Regular;
                        font-size: 1rem;
                        color: #FFFFFF;
                        margin-bottom: 1.06rem;
                    }
                }

            }
        }
        .line{
            width: 100%;
            height: 0.06rem;
            background: #4E545B;
            margin-top: 5rem;
        }
        .footer-bottom{
            text-align: center;
            margin-top: 1.81rem;
                font-size: 0.75rem;
                color: #BCBCBC;
        }
    }
</style>