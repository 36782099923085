<template>
      <div id="header" class="flex-row flex-center default-size" :style="`${headerViewFlag ? 'background: rgba(0,0,0,0.75)':''}`">

        <my-image width="20.63rem" :drag="false" height="2.94rem" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/header/logo2.png"/>
        <div class="header-container">
            <div class="header-nav flex-colum flex-center select">
                    <span class="checked">首页</span>
            </div>
            <div class="header-nav flex-colum flex-center">
                <a :href="`http://www.manbosi.cn/mbs/uk.html?pc=`+this.$route.query.pc"><span>英国</span></a>
            </div>
            <div class="header-nav flex-colum flex-center">
                <a :href="`http://www.manbosi.cn/mbs/usa.html?pc=`+this.$route.query.pc"><span>美国</span></a>
            </div>
            <div class="header-nav flex-colum flex-center">
                <a :href="`http://www.manbosi.cn/mbs/aus.html?pc=`+this.$route.query.pc"><span>澳洲</span></a>
            </div>
             <div class="header-nav flex-colum flex-center">
                <a :href="`http://www.manbosi.cn/mbs/hk.html?pc=`+this.$route.query.pc" class="hover"><span>香港澳门</span></a>
            </div>
            <div class="header-nav flex-colum flex-center">
                <a :href="`http://www.manbosi.cn/mbs/sg.html?pc=`+this.$route.query.pc"><span>新加坡</span></a>
            </div>
            <div class="header-nav flex-colum flex-center">
                <a :href="`http://www.manbosi.cn/mbs/can.html?pc=`+this.$route.query.pc"><span>加拿大</span></a>
            </div>
            <div class="header-nav flex-colum flex-center">
                <a :href="`http://www.manbosi.cn/mbs/eur.html?pc=`+this.$route.query.pc"><span>欧洲</span></a>
            </div>
            <div class="header-nav flex-colum flex-center select">
                <a><span>关于我们</span></a>
                <div class="nav_list_child">
                    <ul>
                        <li><a :href="`http://www.manbosi.cn/tutor/#/?pc=`+this.$route.query.pc">师资力量</a></li>
                        <li><a :href="`http://www.manbosi.cn/student/#/?pc=`+this.$route.query.pc">学员案例</a></li>
                        <!-- <li><a :href="`http://www.manbosi.cn/mbs/aboutMe.html?pc=`+this.$route.query.pc">企业介绍</a></li> -->
                        <!-- <div class="two">
                            <li><a href="https://www.minhandedu.com/mbs/can.html">加拿大</a></li>
                            <li><a href="https://www.minhandedu.com/mbs/europe.html">欧洲</a></li>
                        </div> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="header-nav flex-row flex-align-center" style="margin-right:6rem">
            <my-image :drag="false" src="https://manbosi.oss-cn-shanghai.aliyuncs.com/manbosiIndex/home/header/热线2.png" />
            <div class="tel flex-colum" style="margin-left:0.5rem;">
                <!-- <span style="cursor: auto;color: #fff;">英国：+44 01618175038</span> -->
                <span style="cursor: auto;color: #fff;">总部中国：13761046015</span>
            </div>
        </div>
    </div>
</template>

<script>
import MyImage from '@components/MyImage';
export default {
    data(){
        return {
            headerViewFlag: false
        }
    },
    components:{
        MyImage,
    },
    mounted(){
        window.addEventListener('scroll', e=> {
            if(!this.headerViewFlag){
                if(e.srcElement.scrollingElement.scrollTop > 200){
                    this.headerViewFlag = true;
                }
            }else{
                if(e.srcElement.scrollingElement.scrollTop <= 200){
                    this.headerViewFlag = false;
                }
            }
        })
    }
}
</script>

<style lang="scss" scoped>
    a{
        text-decoration:none;
        color:#000;
    }
    
    
    #header{
        position: fixed;
        top: 0;
        z-index: 999;
        background: rgba(0,0,0,0);
        width: 100%;
        height: 6.20rem;
        transition: background .5s;
        // padding-left:2.5rem;
        // padding-right:2.5rem;
       
        .header-container{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width:50rem;
            .select:hover .nav_list_child{
                display: block;
                animation: hideIndex 0.3s;
                -moz-animation: hideIndex  0.3s; /* Firefox */
                -webkit-animation: hideIndex  0.3s; /* Safari and Chrome */
                -o-animation: hideIndex  0.3s; /* Opera */
            }
            @keyframes hideIndex{
                0%{ opacity: 0; transform: translateY(8rem, 0) }
                100%{opacity: 1; transform: translateY(0, 0) }
            }
            .header-nav{
                display: inline-flex;
                color: #000;
                line-height: 1.38rem;
                // padding-left: calc(2.75rem / 2);
                // padding-right: calc(2.75rem / 2);
                height: 100%;
                letter-spacing: 0.05rem;
                position: relative;
                .hover:hover{
                    transition: all .5s;
                    transform: scale(1.3);
                }
                >a:hover{
                    text-decoration:none;
                    color:  #8071f6;
                    // display: block;
                    transition: all .5s;
                    transform: scale(1.3);

                }
                span{
                    cursor: pointer;
                    user-select: none;
                    // font-size: 0.8rem;
                    color: #fff;
                }
                .nav_list_child{
                    display: none;
                    border-radius:0.2rem;
                    ul{
                        // padding-top: 1.6rem;
                        position: absolute;
                        list-style-type: none;
                        font-size: 0.8rem;
                        left:-1rem;
                        padding-top:.6rem;
                        width: 6rem;
                        height: 4.5rem;
                        // border: 0.1rem solid red;
                        display:flex;
                        flex-direction:column;
                        align-items: center;
                        justify-content: center;
                        // height:0;
                        background:#fff;
                        border-radius:0.5rem;
                        li{
                            width: 6rem;

                        }
                        .one{
                            display:flex;
                            flex-direction: column;
                        }
                        .two{
                            margin-top:0.6rem;
                            display:flex;
                            flex-direction: row;
                        }
                    }
                
                }
                .nav_list_child ul li {
                    line-height:2rem;
                    // width:6rem;
                    text-align: center;
                    // padding-left:-1rem;
                }
                .nav_list_child ul li a{
                    display: block;
                    // width: 10rem;
                }
                .nav_list_child ul li a:hover{
                    color: #fff;
                    background:#8071f6;
                }
                .tel{
                    margin-left: 0.8rem;
                    font-size: 0.88rem;
                    line-height: 1.25rem;
                 
                }
                .checked{
                    // color: #6A328B;
                    color:  #8071f6;
                    // color: #FBBD00;
                    // font-size: 1.25rem;
                    transform:scale(1.3);
                    font-family: PingFangSC-Semibold, sans-serif;
                    // font-weight: bold;
                    
                }
            }
            }
       
        
        // .header-nav:hover{
        //     color: #a172f1;
        // }
    }
</style>
